/*

 	A generic error handler that inserts errors onto the form, received via AJAX. You can
 	customise the behaviour by passing some options to the constructor.
 	@author Matthew Norris
 	
 	Usage (js):

	this.errorHandlerhandler = new AjaxFormErrorHandler([options]);

	Options (passed as a map):

	- useClasses, if true, the selector used to insert error messages will use classes over IDs,
	this is useful if you have multiple inputs on the page with the same "name" attribute.

	...

	$.ajax(endpoint, {
		method: "POST",
		data: data
	}).done(function(response) {
		// response is a Jsonified ValidationResponse java object
		if (this.errorHandler.handleErrors(response)) {
			... error occurred
		} else {
			... no error
		}
	});

 */

var AjaxFormErrorHandler = function(options) {
	this.init = function() {
		this.clear();
		this.selectorPrefix = options && options.useClasses ? '.' : '#'; // Prefix used in selectors when adding errors to the page
		this.parent = options ? options.parent || null : null;
		this.errors = null;
	}

	this.clear = function() {
		// Clear out existing error elements, if their parent form is not a "no-clear"
		// no-clear is for non-AJAX forms, which post off as a page load instead

		$(".form-group").each((i,el) => {
			if ($(el).closest(".no-clear").length == 0) {
				$(el).removeClass("has-error");
			}
		})

		$("form .text-red, form .alert-red").each((i,el) => {
			if ($(el).closest(".no-clear").length == 0) {
				$(el).hide();
			}
		})

		$(".is-invalid").each((i,el) => {
			$(el).removeClass("is-invalid");
		})
	}

	this.handleErrors = function(response) {
		self.clear();
		
		if (!response.errors || response.errors.length == 0) {
			console.log("AjaxFormErrorHandler.handleErrors", "No errors :)");
			return false; // no errors
		}

		self.errors = response.errors;

		console.log("AjaxFormErrorHandler.handleErrors", "Has errors :(");

		// Add new error elements
		for (var i = 0; i < response.errors.length; i++) {
			var error = response.errors[i];

			// The dot / square brackets in the fieldname must be escaped for it to work properly with jQuery's CSS selector
			var selector = self.getErrorSelector(error["fieldName"]);

			console.log("Error:", error["fieldName"], selector);

			$(self.getInputSelector(error["fieldName"])).filter(":not([type=radio],[type=checkbox])").addClass("is-invalid");

			self.highlightErrorSelector(selector, error["message"]);
			if(i == 0) {
				var firstError = this.getElement(selector);
			}
		}

		var modal = firstError.closest(".modal");
		if(modal.length) { 
			// If it's a modal, always scroll to the top
			modal.animate({ scrollTop: 0}, "slow");
		} 
		else {
			// Otherwise scroll based on the position of the first error element.
			// (Going to closest label doesn't work so have to go to .form-group or .row then up another 50px...)
			var closestContainer = firstError.closest(".form-group, .row, .box__content");
			$("html, body").animate({scrollTop: (closestContainer.offset() || firstError.offset() || $("main").offset()).top - 50}, "slow");
		}

		return true; // Indicates that errors were present
	}

	// Convert field name to CSS selector
	this.getErrorSelector = function(fieldName) {
		return this.selectorPrefix + "error_" + fieldName.replace(/([\.\[\]])/g, "\\$1");
	}

	this.getInputSelector = function(fieldName) {
		return "[name=" + fieldName.replace(/([\.\[\]])/g, "\\$1") + "]";
	}

	// Highlight a field by name, can call this directly to get some custom behaviour.
	this.highlightError = function(selector, message) {
		return self.highlightErrorSelector(self.getErrorSelector(selector), message);
	}

	this.getElement = function(selector) {
		return this.parent ? this.parent.find(selector) : $(selector);
	}

	// Highlight a field, used internally
	this.highlightErrorSelector = function(selector, message) {
		var errorElement = this.getElement(selector);

		if (message && !errorElement.hasClass('error-override')) {
			if(message.includes('href')) {
				errorElement.html(message);	
			}
			else {
				errorElement.text(message);
			}
		}
		errorElement.show();
		errorElement.closest(".form-group").addClass("has-error");
	}

	// Retrieve error with given field name
	this.getError = function(fieldName) {
		for (var i = 0; i < self.errors.length; i++) {
			if (self.errors[i].fieldName == fieldName) {
				return self.errors[i];
			}
		}
		return null;
	}

	var self = this;
	this.init();
};

// Make this work with webpack, but still work on the old site.
if(typeof module !== 'undefined') {
	module.exports = AjaxFormErrorHandler;
}